import { Box, Text, Link, Flex, VStack, HStack, Icon, Image } from '@chakra-ui/react';
import { FaWhatsapp, FaEnvelope } from 'react-icons/fa';
import logo from './Logo KVC Azul.webp'; // Asegúrate de actualizar la ruta a tu logo

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const textColor = "#00769b"; // Color de texto

  return (
    <Box as="footer" bg="white" color={textColor} py={8} fontFamily="Poppins, sans-serif">
    



      <Box textAlign="center" mt={4} bg="#1399cd" color="white" py={2}>
        <Text fontSize="sm">
          © {currentYear} Kelly Valle Coach – Todos los Derechos Reservados | 
          <Link href="#" mx={1} color="white">Aviso Legal</Link> | 
          <Link href="#" mx={1} color="white">Políticas de Privacidad</Link>
        </Text>
      </Box>
    </Box>
  );
};

export { Footer };
