import React from 'react';
import { Box, Image, Flex } from '@chakra-ui/react';
import troi from './troi.png';
import banner from './banner.webp'


function ImageComponent() {

    return (
<>

<Box   backgroundImage={banner}
  backgroundPosition="center"
  backgroundSize="cover"
  backgroundRepeat="no-repeat" height="250" />

  <Flex justifyContent="center" alignItems="center">
    <Image
      src={troi}
      alt="Imagen"
      borderRadius="full"
      boxSize="300px" // Ajusta el tamaño deseado para el contenedor de la imagen
      position="relative" // Agrega esta línea para posicionar la imagen dentro del Flex
     // top="50%" // Mueve la imagen hacia arriba en relación con su altura
      transform="translateY(-50%)" // Centra verticalmente la imagen
      objectFit="cover" // Ajusta la imagen dentro del contenedor sin distorsionarla
      maxWidth="100%" // Permite que la imagen se ajuste correctamente en pantallas más pequeñas
      maxHeight="100%" // Permite que la imagen se ajuste correctamente en pantallas más pequeñas

      boxShadow='2xl'
      />
  </Flex>
</>


    );
}
export { ImageComponent }
