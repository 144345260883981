import './App.css';
import { ChakraProvider } from '@chakra-ui/react'
// import { Formulario } from './formulario';
import { Navbar } from './navbar';
import { ImageComponent } from './ImagenComponent';
import { Footer } from './Footer';
import { FormularioExterno } from './formulario_alterno';

function App() {
  return (
<ChakraProvider>



<ImageComponent/>


<FormularioExterno/>

<Footer/>

</ChakraProvider>

  );
}

export default App;
