import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { jsPDF } from 'jspdf';
import {
  FaBook, FaCalendarAlt, FaClock, FaBuilding, FaUser, FaPhone, FaEnvelope, FaBriefcase,
  FaUsers, FaUserTie, FaMapMarkerAlt, FaListAlt, FaMoneyBillWave
} from 'react-icons/fa';
import hojaImage from './hoja.webp';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form"; 


import {
  FormLabel,Box, Button, FormControl, Select, Input, Textarea, VStack, Heading, Container, Step, StepDescription, StepIcon, StepIndicator,
  StepNumber, StepSeparator, StepStatus, StepTitle, Stepper, HStack, InputGroup, FormHelperText, Flex, InputLeftAddon,
  Image, Stack, CheckboxGroup, Checkbox, Spinner, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogBody, AlertDialogFooter, AlertDialogHeader,

} from '@chakra-ui/react';


const pasos = [
  { title: 'Primer Paso', description: 'Información del Cliente' },
  { title: 'Segundo Paso', description: 'Detalles del Evento' },
  { title: 'Tercer Paso', description: 'Objetivos y Metas' },
  { title: 'Cuarto Paso', description: 'Realidad Actual' },

];


const labels = {

  fechaSolicitud: 'Fecha de Solicitud',
  empresa: 'Empresa / Cliente',
  nombreSolicitante: 'Nombre del solicitante',
  cargo: 'Cargo',
  telefono: 'Teléfono',
  correo: 'Correo',
  nombreGerente: 'Nombre del Gerente General',
  numTrabajadores: 'Número de Trabajajadores de la empresa',

  opcionTaller: 'Taller',
  opcionCharla: 'Charla',
  opcionTeamBuilding: 'TeamBuilding',
  horasTaller: 'Horas',
  horasCharla: 'Horas',
  horasTeamBuilding: 'Horas',


  lugarPropuesto: 'Lugar Propuesto',
  fechaPropuesta: 'Fecha Propuesta del Evento',
  numParticipantes: 'Número de participantes',
  presupuesto: 'Presupuesto estimado (US$ Dólar)',

  comentarios: 'Comentarios y Sugerencias',

  metas: '¿Qué metas queremos alcanzar?',
  impacto: '¿Qué impacto tendrá en mi Organización el alcance de estas metas?',
  competencias: '¿Cuáles son las competencias, comportamientos y hábitos que quieren desarrollar?',
  medicion: '¿Cómo vamos a medir el alcance de los objetivos?',
  experiencia: '¿Han tenido una experiencia previa de este servicio solicitado?',
  misionVision: 'Describa brevemente la misión y visión de su empresa u Organización',
  kpis: '¿Cuál será el dindicador de lo que aprendio está funcionando?',
  barreras: '¿Cuáles son las principales barreras que la empresa enfrenta en la actualidad?',
  ads: '¿Cómo se enteró de Kelly Valle Coach?',

};




const FormularioExterno = () => {

  const { register, handleSubmit, reset, formState: { errors }, trigger, getValues, control } = useForm();


  // Obtén la fecha del día de hoy en formato YYYY-MM-DD

  const [showCharla, setShowCharla] = useState(false);
  const [showTaller, setShowTaller] = useState(false);
  const [showTeambuilding, setShowTeambuilding] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const onCloseAlert = () => setShowAlert(false);

  const [selectedOption, setSelectedOption] = useState(null);


  const handleCheckboxChange = (value) => {
    if (selectedOption === value) {
      setSelectedOption(null);
    } else {
      setSelectedOption(value);
    }
  };
  

  const [loading, setLoading] = useState(false);

  const [paso, setPaso] = useState(0);

  const siguientePaso = async () => {
    const result = await trigger();  // verifica si todos los campos son válidos

    if (result) {
      if (paso < pasos.length - 1) {
        setPaso(paso + 1);
      } else {
        onSubmit(getValues());
      }
    }
  };


  const anteriorPaso = () => {
    if (paso > 0) {
      setPaso(paso - 1);
    }
  };


  function getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then(res => res.blob())
        .then(blob => {
          var reader = new FileReader();
          reader.onloadend = function () {
            let base64data = reader.result;
            resolve(base64data);
          }
          reader.readAsDataURL(blob);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  const onSubmit = async (data) => {
    //const logo = await getBase64ImageFromURL(logoImage);

    console.log(data);
    const backgroundImage = await getBase64ImageFromURL(hojaImage);



    // Obtener la fecha actual y convertirla a una cadena en formato local
    const now = new Date();
    data.fechaSolicitud = now.toLocaleDateString("es-ES", { day: 'numeric', month: 'numeric', year: 'numeric' });
    data.fechaPropuesta =  data.fechaPropuesta.toLocaleDateString("es-ES", { day: 'numeric', month: 'numeric', year: 'numeric' });

    const doc = new jsPDF();
    let yPos = 35;
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 10;
    const maxWidth = pageWidth - 2 * margin;
    const footerMargin = 30;
    let pageNum = 1;

    function addHeaderFooter() {
      // Añadir logo
      //doc.addImage(logo, 'PNG', margin, -8, 43, 43);
      doc.addImage(backgroundImage, 'PNG', 0, 0, pageWidth, pageHeight, undefined, 'NONE');

      // Añadir encabezado
      // doc.setLineWidth(0.5);
      // doc.setDrawColor(19, 153, 205);
      // doc.line(margin, 20, pageWidth - margin, 20);
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Retorno sobre la Inversión de Formación (TROI) ", pageWidth / 2, 32, { align: "center" });

      // Añadir pie de página
      // doc.setLineWidth(0.5);

      doc.setFontSize(10);
      doc.text(`Página ${pageNum}`, pageWidth - margin, pageHeight - 10, { align: "right" });
      // doc.text("+505 8888-9999 / +505 8888-9999", pageWidth / 2, pageHeight - 10, { align: "center" });
      pageNum++;
    }

    addHeaderFooter();





// Función para agregar una nueva línea o página si es necesario
function addNewLineOrPage(lineSpacing) {
  if (yPos + lineSpacing > pageHeight - footerMargin) {
    doc.addPage();
    yPos = 30;
    addHeaderFooter();
  } else {
    yPos += lineSpacing;
  }
}


function addNewLineOrPage(lineSpacing) {
  if (yPos + lineSpacing > pageHeight - footerMargin) {
    doc.addPage();
    yPos = 30;
    addHeaderFooter();
  } else {
    yPos += lineSpacing;
  }
}

const singleLineKeys = new Set([
  'fechaSolicitud','empresa', 'nombreSolicitante', 'cargo', 'telefono', 'correo', 'nombreGerente',
  'numTrabajadores', 'opcionTaller', 'opcionCharla', 'opcionTeamBuilding','opcionAssessment','opcionGestionTalento', 'horasTaller',
  'horasCharla', 'horasTeamBuilding','horasAssessment','horasGestionTalento','lugarPropuesto','fechaPropuesta','numParticipantes','presupuesto'
]);

const orderedKeys = [
  'fechaSolicitud', 'empresa', 'nombreSolicitante', 'cargo', 'telefono', 'correo',
  'nombreGerente', 'numTrabajadores', 'opcionTaller', 'opcionCharla', 'opcionTeamBuilding',
  'opcionAssessment','opcionGestionTalento','horasGestionTalento','horasTaller', 'horasCharla', 'horasTeamBuilding','horasAssessment','lugarPropuesto','fechaPropuesta',
  'numParticipantes','presupuesto', 'comentarios', 'metas', 'impacto', 'competencias',
  'medicion', 'experiencia', 'misionVision', 'kpis', 'barreras','ads'
];

orderedKeys.forEach(key => {
  let value = data[key];

  if (value !== undefined) {
    // Establecer fuente en negrita y obtener el ancho de la etiqueta
    doc.setFont("helvetica", "bold");
    let label = `${labels[key]}: `;
    let labelWidth = doc.getTextWidth(label);

    // Verificar si necesitamos agregar una nueva página antes de escribir la etiqueta
    addNewLineOrPage(singleLineKeys.has(key) ? 3 : 6);

    // Escribir la etiqueta en negrita
    doc.text(label, margin, yPos);

    // Establecer fuente normal
    doc.setFont("helvetica", "normal");

    if (singleLineKeys.has(key)) {
      doc.text(value, margin + labelWidth, yPos);
      addNewLineOrPage(5);
    } else {
      // Dividir el valor en líneas y escribir cada línea
      let lines = doc.splitTextToSize(value, maxWidth - margin);
      for (let line of lines) {
        addNewLineOrPage(5);
        doc.text(line, margin, yPos);
      }
    }
  }
});






    doc.save('troikvc.pdf');

    const pdfBase64 = doc.output('datauristring').split(',')[1];
    const client = data.empresa;
    const email = data.correo;
    const url_data = "https://biolink.info/KellyValleCoach "

    setLoading(true);

    try {
      // Envía el PDF a tu API de Django
      const response = await fetch('https://www.apitroi.kellyvallecoach.com/send_email/', {
        // const response = await fetch('http://127.0.0.1:8000/send_email/', {

        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          client: client,
          pdf: pdfBase64,
          email: email,
          url: url_data,
          // Puedes agregar más datos aquí si es necesario
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      reset();
      setPaso(0);
      setShowAlert(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Cuando la petición ha terminado (tanto si fue exitosa como si hubo un error), establece loading en false
    }





  };


  return (

    <Container maxW={["container.sm", "container.md", "container.lg", "container.xl"]} p={0} mt={-12} paddingBottom="5em">

      <Stepper size={['xs', 'xs', 'sm', 'sm']} gap='0' index={paso} marginBottom={3}>
        {pasos.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <Box flexShrink='0'>
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>

      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="10vh">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl" // Cambia esto para ajustar el tamaño del spinner
          />
        </Box>
      ) : showAlert ? (
        <AlertDialog isOpen={showAlert} onClose={onCloseAlert}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader>Envío exitoso</AlertDialogHeader>
              <AlertDialogBody>El PDF ha sido enviado correctamente.</AlertDialogBody>
              <AlertDialogFooter>
                <Button onClick={onCloseAlert}>Aceptar</Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      ) : (

        <form>
          <VStack spacing={3} direction={["column", "column", "row", "row"]}>

            {/* Código de cada paso */}

            {paso === 0 && (
              <>
             
   

              
                <FormControl id="empresa" isInvalid={errors.empresa} isRequired>
                  <InputGroup>
                    <InputLeftAddon pointerEvents="none">
                      <FaBuilding color="gray.300" />
                    </InputLeftAddon>
                    <Input type="text" {...register('empresa', { required: "Este campo es requerido" })} placeholder="Nombre de la Empresa / Cliente" />
                  </InputGroup>
                  {errors.empresa && <FormHelperText color="red.500">{errors.empresa.message}</FormHelperText>}
                </FormControl>

                <Flex w="100%" justifyContent="space-between">
                  <Box w="49%">

                    <FormControl id="nombreSolicitante" isInvalid={errors.nombreSolicitante} isRequired>
                      <InputGroup>
                        <InputLeftAddon pointerEvents="none">
                          <FaUser color="gray.300" />
                        </InputLeftAddon>
                        <Input type="text" {...register('nombreSolicitante', { required: "Este campo es requerido" })} placeholder='Nombre de quien solicita la Oferta' />
                      </InputGroup>
                      {errors.nombreSolicitante && <FormHelperText color="red.500">{errors.nombreSolicitante.message}</FormHelperText>}
                    </FormControl>
                  </Box>

                  <Box w="49%">

                    <FormControl id="cargo" isInvalid={errors.cargo} isRequired>
                      <InputGroup>
                        <InputLeftAddon pointerEvents="none">
                          <FaBriefcase color="gray.300" />
                        </InputLeftAddon>
                        <Input type="text" {...register('cargo', { required: "Este campo es requerido" })} placeholder='Cargo' />
                      </InputGroup>
                      {errors.cargo && <FormHelperText color="red.500">{errors.cargo.message}</FormHelperText>}
                    </FormControl>

                  </Box>
                </Flex>

                <Flex w="100%" justifyContent="space-between">
                  <Box w="49%">

                    <FormControl id="telefono" isInvalid={errors.telefono} isRequired>
                      <InputGroup>
                        <InputLeftAddon pointerEvents="none">
                          <FaPhone color="gray.300" />
                        </InputLeftAddon>
                        <Input
                          type="text"
                          onKeyDown={event => {
                            const key = event.key;
                            // Lista de teclas permitidas además de los números del 0 al 9.
                            const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Home', 'End'];
                            if ((!/[0-9]/.test(key) && !allowedKeys.includes(key)) ||
                              (event.currentTarget.value.length >= 8 && !allowedKeys.includes(key))) {
                              event.preventDefault();
                            }
                          }}
                          {...register('telefono', {
                            required: "Este campo es requerido",
                            validate: value => (value.length === 8) || "El número de teléfono debe tener exactamente 8 dígitos"
                          })}
                          placeholder='Teléfono'
                        />
                      </InputGroup>
                      {errors.telefono && <FormHelperText color="red.500">{errors.telefono.message}</FormHelperText>}
                    </FormControl>

                  </Box>

                  <Box w="49%">

                    <FormControl id="correo" isInvalid={errors.correo} isRequired>
                      <InputGroup>
                        <InputLeftAddon pointerEvents="none">
                          <FaEnvelope color="gray.300" />
                        </InputLeftAddon>
                        <Input
                          type="email"
                          {...register('correo', {
                            required: "Este campo es requerido",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: "La dirección de correo electrónico es inválida"
                            }
                          })}
                          placeholder='Correo'
                        />
                      </InputGroup>
                      {errors.correo && <FormHelperText color="red.500">{errors.correo.message}</FormHelperText>}
                    </FormControl>

                  </Box>
                </Flex>


                <Flex w="100%" justifyContent="space-between">
                  <Box w="49%">

                    <FormControl id="nombreGerente" isInvalid={errors.nombreGerente} isRequired>
                      <InputGroup>
                        <InputLeftAddon pointerEvents="none">
                          <FaUser color="gray.300" />
                        </InputLeftAddon>
                        <Input type="text" {...register('nombreGerente', { required: "Este campo es requerido" })} placeholder='Nombre del Gerente General' />
                      </InputGroup>
                      {errors.nombreGerente && <FormHelperText color="red.500">{errors.nombreGerente.message}</FormHelperText>}
                    </FormControl>

                  </Box>

                  <Box w="49%">

                    <FormControl id="numTrabajadores" isInvalid={errors.numTrabajadores} isRequired>
                      <InputGroup>
                        <InputLeftAddon pointerEvents="none">
                          <FaUsers color="gray.300" />
                        </InputLeftAddon>
                        <Input
                          type="number"
                          onKeyDown={event => {
                            const key = event.key;
                            // Lista de teclas permitidas además de los números del 1 al 9.
                            const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Home', 'End'];
                            if (!/[0-9]/.test(key) && !allowedKeys.includes(key)) {
                              event.preventDefault();
                            }
                          }}
                          {...register('numTrabajadores', {
                            required: "Este campo es requerido",
                            validate: value => (!isNaN(value) && parseInt(value) > 0 && parseInt(value) <= 1000) || "Solo se permiten números entre 1 y 1000"
                          })}
                          placeholder='Número de Trabajadores'
                        />
                      </InputGroup>
                      {errors.numTrabajadores && <FormHelperText color="red.500">{errors.numTrabajadores.message}</FormHelperText>}
                    </FormControl>

                  </Box>
                </Flex>

              </>
            )}

            {paso === 1 && (
              <>

<Stack spacing={[1, 5]} direction={['column', 'row']}>
  <Checkbox 
    value='taller' 
    isChecked={selectedOption === 'taller'}
    onChange={() => handleCheckboxChange('taller')}
  >
    Taller
  </Checkbox>
  <Checkbox 
    value='charla' 
    isChecked={selectedOption === 'charla'}
    onChange={() => handleCheckboxChange('charla')}
  >
    Charla
  </Checkbox>
  <Checkbox 
    value='teamBuilding' 
    isChecked={selectedOption === 'teamBuilding'}
    onChange={() => handleCheckboxChange('teamBuilding')}
  >
    Teambuilding
  </Checkbox>

  <Checkbox 
    value='assessment' 
    isChecked={selectedOption === 'assessment'}
    onChange={() => handleCheckboxChange('assessment')}
  >
    Assessment
  </Checkbox>

  <Checkbox 
    value='gestionTalento' 
    isChecked={selectedOption === 'gestionTalento'}
    onChange={() => handleCheckboxChange('gestionTalento')}
  >
    Gestión del Talento Humano
  </Checkbox>
</Stack>




                {selectedOption === 'taller' &&  (

                  <Flex w="100%" justifyContent="space-between">
                    <Box w="49%">
                      <FormControl id="opcionTaller" isInvalid={errors.opciones} isRequired>
                        <InputGroup>
                          <InputLeftAddon pointerEvents="none">
                            <FaListAlt color="gray.300" />
                          </InputLeftAddon>
                          <Select placeholder="Selecciona una opción" {...register('opcionTaller', { required: "Este campo es requerido" })}>
                            <option value='Inteligencia Emocional'>Inteligencia Emocional</option>
                            <option value='Gestión productiva del tiempo'>Gestión productiva del tiempo</option>
                            <option value='Estrategias para desarrollar equipos de alto rendimiento'>Estrategias para desarrollar equipos de alto rendimiento</option>
                            <option value='Propósito y desarrollo del potencial'>Propósito y desarrollo del potencial</option>
                            <option value='Visión y propósito de Equipo'>Visión y propósito de Equipo</option>
                            <option value='Comunicación efectiva'>Comunicación efectiva</option>
                            <option value='Habilidades Directivas'>Habilidades Directivas</option>
                            <option value='Estrategias para conseguir empleo'>Estrategias para conseguir empleo</option>
                            <option value='Valora tu Historia de Vida'>Valora tu Historia de Vida</option>
                            <option value='Seguridad Psicológica'>Seguridad Psicológica</option>


                          </Select>
                        </InputGroup>
                        {errors.opcionTaller && <FormHelperText color="red.500">{errors.opcionTaller.message}</FormHelperText>}
                      </FormControl>

                    </Box>

                    <Box w="49%">
                      <FormControl id="horasTaller" isInvalid={errors.horasTaller} isRequired>
                        <InputGroup>
                          <InputLeftAddon pointerEvents="none">
                            <FaListAlt color="gray.300" />
                          </InputLeftAddon>
                          <Select placeholder="Selecciona una opción" {...register('horasTaller', { required: "Este campo es requerido" })}>

                            <option value='4 Horas'>4 Horas</option>
                            <option value='8 Horas'>8 Horas</option>
                          </Select>
                        </InputGroup>
                        {errors.horasTaller && <FormHelperText color="red.500">{errors.horasTaller.message}</FormHelperText>}
                      </FormControl>

                    </Box>

                  </Flex>
                )}
                {selectedOption === 'charla' && (
                  <Flex w="100%" justifyContent="space-between">

                    <Box w="49%">
                      <FormControl id="opcionCharla" isInvalid={errors.opciones} isRequired>
                        <InputGroup>
                          <InputLeftAddon pointerEvents="none">
                            <FaListAlt color="gray.300" />
                          </InputLeftAddon>
                          <Select placeholder="Selecciona una opción" {...register('opcionCharla', { required: "Este campo es requerido" })}>
                            <option value='Inteligencia Emocional'>Inteligencia Emocional</option>
                            <option value='Gestión productiva del tiempo'>Gestión productiva del tiempo</option>
                            <option value='Propósito y desarrollo del potencial'>Propósito y desarrollo del potencial</option>
                            <option value='Liderazgo personal'>Liderazgo personal</option>
                            <option value='Liderazgo Colaborativo'>Liderazgo Colaborativo</option>
                            <option value='Visión y propósito de Equipo'>Visión y propósito de Equipo</option>
                            <option value='Comunicación efectiva'>Comunicación efectiva</option>
                            <option value='Habilidades Directivas'>Habilidades Directivas</option>
                            <option value='Estrategias para conseguir empleo'>Estrategias para conseguir empleo</option>
                            <option value='Valora tu Historia de Vida'>Valora tu Historia de Vida</option>
                            <option value='Seguridad Psicológica'>Seguridad Psicológica</option>


                          </Select>
                        </InputGroup>
                        {errors.opcionCharla && <FormHelperText color="red.500">{errors.opcionCharla.message}</FormHelperText>}
                      </FormControl>
                    </Box>

                    <Box w="49%">

                      <FormControl id="horasCharla" isInvalid={errors.horasCharla} isRequired>
                        <InputGroup>
                          <InputLeftAddon pointerEvents="none">
                            <FaListAlt color="gray.300" />
                          </InputLeftAddon>
                          <Select placeholder="Selecciona una opción" {...register('horasCharla', { required: "Este campo es requerido" })}>
                            <option value='1 Hora'>1 Hora</option>
                            <option value='2 Horas'>2 Horas</option>

                          </Select>
                        </InputGroup>
                        {errors.horasCharla && <FormHelperText color="red.500">{errors.horasCharla.message}</FormHelperText>}
                      </FormControl>

                    </Box>

                  </Flex>
                )}
                {selectedOption === 'teamBuilding' && (
                  <Flex w="100%" justifyContent="space-between">

                    <Box w="49%">

                      <FormControl id="opcionTeamBuilding" isInvalid={errors.opciones} isRequired>
                        <InputGroup>
                          <InputLeftAddon pointerEvents="none">
                            <FaListAlt color="gray.300" />
                          </InputLeftAddon>
                          <Select placeholder="Selecciona una opción" {...register('opcionTeamBuilding', { required: "Este campo es requerido" })}>
                            <option value='Inteligencia Emocional'>Inteligencia Emocional</option>
                            <option value='Gestión productiva del tiempo'>Gestión productiva del tiempo</option>
                            <option value='Estrategias para desarrollar equipos de alto rendimiento'>Estrategias para desarrollar equipos de alto rendimiento</option>
                            <option value='Liderazgo Colaborativo'>Liderazgo Colaborativo</option>
                            <option value='Visión y propósito de Equipo'>Visión y propósito de Equipo</option>
                            <option value='Comunicación efectiva'>Comunicación efectiva</option>
                            <option value='Seguridad Psicológica'>Seguridad Psicológica</option>


                          </Select>
                        </InputGroup>
                        {errors.opcionTeamBuilding && <FormHelperText color="red.500">{errors.opcionTeamBuilding.message}</FormHelperText>}
                      </FormControl>

                    </Box>

                    <Box w="49%">
                      <FormControl id="horasTeamBuilding" isInvalid={errors.horasTeamBuilding} isRequired>
                        <InputGroup>
                          <InputLeftAddon pointerEvents="none">
                            <FaListAlt color="gray.300" />
                          </InputLeftAddon>
                          <Select placeholder="Selecciona una opción" {...register('horasTeamBuilding', { required: "Este campo es requerido" })}>

                            <option value='4 Horas'>4 Horas</option>
                            <option value='8 Horas'>8 Horas</option>

                          </Select>
                        </InputGroup>
                        {errors.horasTeamBuilding && <FormHelperText color="red.500">{errors.horasTeamBuilding.message}</FormHelperText>}
                      </FormControl>

                    </Box>

                  </Flex>
                )}
               {selectedOption === 'assessment' && (
                  <Flex w="100%" justifyContent="space-between">

                    <Box w="49%">

                      <FormControl id="opcionAssessment" isInvalid={errors.opciones} isRequired>
                        <InputGroup>
                          <InputLeftAddon pointerEvents="none">
                            <FaListAlt color="gray.300" />
                          </InputLeftAddon>
                          <Select placeholder="Selecciona una opción" {...register('opcionAssessment', { required: "Este campo es requerido" })}>
                            <option value='Análisis del entorno empresarial'>Análisis del entorno empresarial</option>
                            <option value='Análisis Interno Empresarial'>Análisis Interno Empresarial</option>
                            <option value='Estudio de clima'>Estudio de clima</option>
                            <option value='Selección, reclutamiento e Incorporación de personal'>Selección, reclutamiento e Incorporación de personal</option>
                            <option value='Evaluación al Desempeño'>Evaluación al Desempeño</option>
                            <option value='Desarrollo y planes de Sucesión'>Desarrollo y planes de Sucesión</option>
                            <option value='Formación laboral y Análisis de necesidades'>Formación laboral y Análisis de necesidades</option>
                            <option value='Formación laboral y Análisis de necesidades'>Post-Assessment</option>


                          </Select>
                        </InputGroup>
                        {errors.opcionAssessment && <FormHelperText color="red.500">{errors.opcionAssessment.message}</FormHelperText>}
                      </FormControl>

                    </Box>

                    <Box w="49%">
                      <FormControl id="horasAssessment" isInvalid={errors.horasAssessment} isRequired>
                        <InputGroup>
                          <InputLeftAddon pointerEvents="none">
                            <FaListAlt color="gray.300" />
                          </InputLeftAddon>
                          <Select placeholder="Selecciona una opción" {...register('horasAssessment', { required: "Este campo es requerido" })}>

                            <option value='4 Horas'>4 Horas</option>
                            <option value='8 Horas'>8 Horas</option>
                            <option value='12 Horas'>12 Horas</option>
                            <option value='16 Horas'>16 Horas</option>
                            <option value='20 Horas'>20 Horas</option>
                            <option value='24 Horas'>24 Horas</option>
                            <option value='28 Horas'>28 Horas</option>
                            <option value='32 Horas'>32 Horas</option>

                          </Select>
                        </InputGroup>
                        {errors.horasAssessment && <FormHelperText color="red.500">{errors.horasAssessment.message}</FormHelperText>}
                      </FormControl>

                    </Box>

                  </Flex>
                )}
                  {selectedOption === 'gestionTalento' && (
                  <Flex w="100%" justifyContent="space-between">

                    <Box w="49%">

                      <FormControl id="opcionGestionTalento" isInvalid={errors.opcionGestionTalento} isRequired>
                        <InputGroup>
                          <InputLeftAddon pointerEvents="none">
                            <FaListAlt color="gray.300" />
                          </InputLeftAddon>
                          <Select placeholder="Selecciona una opción" {...register('opcionGestionTalento', { required: "Este campo es requerido" })}>
                            <option value='Planificación estratégica empresarial'>Planificación estratégica empresarial</option>
                            <option value='Desarrollo y alineamiento organizacional'>Desarrollo y alineamiento organizacional</option>
                            <option value='Asesoría laboral'>Asesoría laboral</option>
                            <option value='Modelo de Negocio'>Modelo de Negocio</option>
                            <option value='Desarrollo de Equipos de Alto Rendimiento'>Desarrollo de Equipos de Alto Rendimiento</option>
                            <option value='Comunicación de Alto Impacto'>Desarrollo y planes de Sucesión</option>
                            <option value='Liderazgo y Habilidades Directivas'>Liderazgo y Habilidades Directivas</option>
                            <option value='Inteligencia Emocional y Gestión Productiva del Tiempo'>Inteligencia Emocional y Gestión Productiva del Tiempo</option>


                          </Select>
                        </InputGroup>
                        {errors.opcionGestionTalento && <FormHelperText color="red.500">{errors.opcionGestionTalento.message}</FormHelperText>}
                      </FormControl>

                    </Box>

                    <Box w="49%">
                      <FormControl id="horasGestionTalento" isInvalid={errors.horasGestionTalento} isRequired>
                        <InputGroup>
                          <InputLeftAddon pointerEvents="none">
                            <FaListAlt color="gray.300" />
                          </InputLeftAddon>
                          <Select placeholder="Selecciona una opción" {...register('horasGestionTalento', { required: "Este campo es requerido" })}>

                          <option value='2 Horas'>2 Horas</option>
                            <option value='4 Horas'>4 Horas</option>
                            <option value='8 Horas'>8 Horas</option>
                            <option value='12 Horas'>12 Horas</option>
                           

                          </Select>
                        </InputGroup>
                        {errors.horasGestionTalento && <FormHelperText color="red.500">{errors.horasGestionTalento.message}</FormHelperText>}
                      </FormControl>

                    </Box>

                  </Flex>
                )}




                <Flex w="100%" justifyContent="space-between">
                  <Box w="49%">

                    {/* <FormControl id="fechaPropuesta" isInvalid={errors.lugarEvento} isRequired>

                      <InputGroup>
                        <InputLeftAddon pointerEvents="none">
                          <FaCalendarAlt color="gray.300" />
                        </InputLeftAddon>
                        <Input type="text" {...register('fechaPropuesta', { required: "Este campo es requerido" })} placeholder="Fecha Propuesta" />
                      </InputGroup>
                      {errors.fechaPropuesta && <FormHelperText color="red.500">{errors.fechaPropuesta.message}</FormHelperText>}
                    </FormControl> */}

<FormControl id="lugarPropuesto" isInvalid={errors.lugarEvento} isRequired>

                      <InputGroup>
                        <InputLeftAddon pointerEvents="none">
                          <FaMapMarkerAlt color="gray.300" />
                        </InputLeftAddon>
                        <Input type="text" {...register('lugarPropuesto', { required: "Este campo es requerido" })} placeholder="Lugar Propuesto" />
                      </InputGroup>
                      {errors.lugarPropuesto && <FormHelperText color="red.500">{errors.lugarPropuesto.message}</FormHelperText>}
                    </FormControl>


                  </Box>


                  <Box w="49%">
                  <FormControl id="presupuesto" isInvalid={errors.presupuesto} isRequired>

<InputGroup>
  <InputLeftAddon pointerEvents="none">
    <FaMoneyBillWave color="gray.300" />
  </InputLeftAddon>
  <Input type="number" {...register('presupuesto', { required: "Este campo es requerido" })} placeholder='Presupuesto estimado (US$ Dólar)' />
</InputGroup>
{errors.presupuesto && <FormHelperText color="red.500">{errors.presupuesto.message}</FormHelperText>}
</FormControl>
                    
                  </Box>

                </Flex>



                <Flex w="100%" justifyContent="space-between">
                  <Box w="49%">
                    <FormControl id="numParticipantes" isInvalid={errors.opciones} isRequired>
                      <InputGroup>
                        <InputLeftAddon pointerEvents="none">
                          <FaUsers color="gray.300" />
                        </InputLeftAddon>
                        <Input type="number" {...register('numParticipantes', { required: "Este campo es requerido" })} placeholder='Número de participantes' />
                      </InputGroup>
                      {errors.numParticipantes && <FormHelperText color="red.500">{errors.numParticipantes.message}</FormHelperText>}
                    </FormControl>

                  </Box>


                  <Box w="49%" position="relative" zIndex="10">

                  <FormControl id="fechaPropuesta" isInvalid={errors.fechaPropuesta} isRequired>

<InputGroup>
  <InputLeftAddon children={<FaCalendarAlt color="gray.300" />} />
  <Controller
    control={control} // Asegúrate de pasar el objeto control de react-hook-form
    name="fechaPropuesta"
    render={({ field }) => (
      <DatePicker
      selected={field.value}
      onChange={(date) => field.onChange(date)}
      customInput={<Input style={{ width: "100%" }} />} // Ajusta el ancho aquí
      dateFormat="MMMM d, yyyy"
      isClearable
      placeholderText="Fecha Propuesta"
    
      />
    )}
  />
</InputGroup>
{errors.fechaPropuesta && <FormHelperText color="red.500">{errors.fechaPropuesta.message}</FormHelperText>}
</FormControl>

                  </Box>

                </Flex>






              </>
            )}

            {paso === 2 && (

              <>



                <FormControl id="metas" isInvalid={errors.metas} isRequired>
                  <InputGroup>
                    <Textarea {...register('metas', { required: "Este campo es requerido" })} placeholder='¿Qué metas queremos alcanzar?' />
                  </InputGroup>
                  {errors.metas && <FormHelperText color="red.500">{errors.metas.message}</FormHelperText>}
                </FormControl>

                <FormControl id="impacto" isInvalid={errors.impacto} isRequired>
                  <InputGroup>
                    <Textarea {...register('impacto', { required: "Este campo es requerido" })} placeholder='¿Qué impacto tendrá en mi Organización el alcance de estas metas?' />
                  </InputGroup>
                  {errors.impacto && <FormHelperText color="red.500">{errors.impacto.message}</FormHelperText>}
                </FormControl>

                <FormControl id="competencias" isInvalid={errors.competencias} isRequired>
                  <InputGroup>
                    <Textarea {...register('competencias', { required: "Este campo es requerido" })} placeholder='¿Cuáles son las competencias, comportamientos y hábitos que quieren desarrollar?' />
                  </InputGroup>
                  {errors.competencias && <FormHelperText color="red.500">{errors.competencias.message}</FormHelperText>}
                </FormControl>

                <FormControl id="medicion" isInvalid={errors.medicion} isRequired>
                  <InputGroup>
                    <Textarea {...register('medicion', { required: "Este campo es requerido" })} placeholder='¿Cómo vamos a medir el alcance de los objetivos?' />
                  </InputGroup>
                  {errors.medicion && <FormHelperText color="red.500">{errors.medicion.message}</FormHelperText>}
                </FormControl>




              </>

            )}

            {paso === 3 && (
              <>
                <Flex w="100%" justifyContent="space-between">
                  <Box w="49%">
                    <FormControl id="experiencia" isInvalid={errors.experiencia} isRequired>
                      <Textarea {...register('experiencia', { required: "Este campo es requerido" })} placeholder='¿Han tenido una experiencia previa de este servicio solicitado?' />
                      {errors.experiencia && <FormHelperText color="red.500">{errors.experiencia.message}</FormHelperText>}
                    </FormControl>
                  </Box>
                  <Box w="49%">
                    <FormControl id="misionVision" isInvalid={errors.misionVision} isRequired>
                      <Textarea {...register('misionVision', { required: "Este campo es requerido" })} placeholder='Describa brevemente la misión y visión de su empresa u Organización' />
                      {errors.misionVision && <FormHelperText color="red.500">{errors.misionVision.message}</FormHelperText>}
                    </FormControl>
                  </Box>
                </Flex>

                <Flex w="100%" justifyContent="space-between">
                  <Box w="49%">
                    <FormControl id="kpis" isInvalid={errors.kpis} isRequired>
                      <Textarea {...register('kpis', { required: "Este campo es requerido" })} placeholder='¿Cuál será el indicador de lo que aprendio está funcionando' />
                      {errors.kpis && <FormHelperText color="red.500">{errors.kpis.message}</FormHelperText>}
                    </FormControl>
                  </Box>
                  <Box w="49%">
                    <FormControl id="barreras" isInvalid={errors.barreras} isRequired>
                      <Textarea {...register('barreras', { required: "Este campo es requerido" })} placeholder='¿Cuáles son las principales retos y obstáculos que actualmente impiden?' />
                      {errors.barreras && <FormHelperText color="red.500">{errors.barreras.message}</FormHelperText>}
                    </FormControl>
                  </Box>
                </Flex>
                <FormControl id="ads" isInvalid={errors.barreras} isRequired>
                      <Textarea {...register('ads', { required: "Este campo es requerido" })} placeholder='¿Cómo se enteró de Kelly Valle Coach?' />
                      {errors.ads && <FormHelperText color="red.500">{errors.ads.message}</FormHelperText>}
                    </FormControl>





              </>
            )}






            <HStack spacing={4}>
              <Button onClick={anteriorPaso} disabled={paso === 0}>
                Anterior
              </Button>
              <Button onClick={siguientePaso} disabled={paso === pasos.length - 1}>
                Siguiente
              </Button>
            </HStack>
          </VStack>
        </form>
      )}
    </Container>

  );
};

export { FormularioExterno };




